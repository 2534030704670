import React, { FC, useContext, useEffect, useState } from "react";
import { PageProps } from "../../models/Page";
import { useAPI } from "../../hooks/useAPI";
import Loading from "../../components/Loading";
import ErrorLoading from "../../components/ErrorLoading";
import { useConfig } from "../../hooks/useConfig";
import clsx from "clsx";
import { PaginationReservations, ReservationAvailability, ReservationConfig, ReservationModel, ReservationStats } from "../../models/Reservation";
import ReservationAddModal from "./ReservationAddModal";
import { useHelper } from "../../hooks/useHelper";
import ReservationRow from "./ReservationRow";
import Success from "../../components/messages/Success";
import Confirm from "../../components/messages/Confirm";
import ConfirmModal from "./components/ConfirmModal";
import moment from "moment";

const ReservationsConfigPage: FC<PageProps> = ({ updateToolbar, business_branch }) => {
    useEffect(() => {
        updateToolbar({
            title: "Configurações",
            breadcrumbs: [
                {
                    title: "Agendamento",
                    path: '#',
                    isSeparator: false,
                    isActive: false,
                },
                {
                    title: '',
                    path: '',
                    isSeparator: true,
                    isActive: false,
                },
            ],
            buttons: []
        });
        loadConfigs();
    }, []);

    const [loading, setLoading] = useState(true);
    const { api } = useAPI();
    const [showSuccess, setShowSuccess] = useState(false);
    const [loadingFull, setLoadingFull] = useState(false);

    const [config, setConfig] = useState<ReservationConfig | undefined>(undefined);
    const [oldConfig, setOldConfig] = useState<ReservationConfig | undefined>(undefined);
    const loadConfigs = () => {
        api().get(`/api/business/${business_branch.business_id}/branch/${business_branch.id}/reservation-config`).then((resp) => {
            let savedConfig = resp.data ? resp.data : {
                business_branch_id: business_branch.id,
                accept_reservations: true,
                accept_online_reservations: true,
                accept_phone_reservations: false,
                auto_accept_reservations: false,
                auto_accept_reservations_max: 1,
                min_person_per_reservation: 1,
                max_person_per_reservation: 1,
                reservations_interval: 10,
                minimum_antecedence: 60,
                maximum_antecedence: 60
            };
            setConfig(savedConfig);
            setOldConfig(savedConfig);
            setLoading(false);
        }).catch(() => {
            setLoading(false);
        });
    }

    const saveConfigs = (configs: ReservationConfig) => {
        setLoadingFull(true);
        api()
            .post(`/api/business/${business_branch.business_id}/branch/${business_branch.id}/reservation-config`, configs)
            .then((resp) => {
                setLoadingFull(false);
                if (resp.data.success) {
                    setShowSuccess(true);
                } else {
                    alert('Houve um erro, tente novamente.');
                }
            })
            .catch(() => {
                setLoadingFull(false);
                alert('Houve um erro, tente novamente.')
            });
    }

    return (
        <>
            {loadingFull && (
                <Loading fullscreen={true} />
            )}
            {showSuccess && (
                <Success
                    message={"Configurações salvas com sucesso!"}
                    onClick={() => {
                        setShowSuccess(false);
                    }}
                />
            )}
            {loading && (
                <div style={{
                    "position": "relative",
                    "minHeight": "100px",
                    "paddingTop": "40%"
                }}>
                    <Loading />
                </div>
            )}
            {!loading && config && oldConfig && (
                <div className="post d-flex flex-column-fluid" id="kt_post">
                    <div className="container" id="kt_content_container">
                        <div className="col--12 mb-5 mb-xl-10">
                            <div className="row g-7">
                                <div className="col-lg-4 col-12 ">
                                    <div className="card card-flush mb-3">
                                        <div className="card-header">
                                            <h3 className="card-title">
                                                <span className="card-label fw-bold text-dark">Agendamento</span>
                                                {config.accept_reservations && (<span className="badge badge-success">Ativado</span>)}
                                                {!config.accept_reservations && (<span className="badge badge-danger">Desativado</span>)}
                                            </h3>
                                        </div>
                                        <div className="card-body pt-2">
                                            <div className="py-2">
                                                <div className="d-flex flex-stack">
                                                    <div className="d-flex">
                                                        <div className="d-flex flex-column">
                                                            <a href="#" className="fs-5 text-dark text-hover-primary fw-bold">Aceitar reservas</a>
                                                            <div className="fs-6 fw-semibold text-muted pe-5">Ative para permitir agendamentos no seu estabelecimento.</div>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex justify-content-end">
                                                        <label className="form-check form-switch form-switch-sm form-check-custom form-check-solid">
                                                            <input
                                                                onChange={(e) => {
                                                                    setConfig({ ...config, accept_reservations: e.target.checked });
                                                                    let configsToSave = {
                                                                        ...oldConfig,
                                                                        accept_reservations: e.target.checked
                                                                    };
                                                                    setOldConfig(configsToSave);
                                                                    saveConfigs(configsToSave);
                                                                }}
                                                                className="form-check-input h-30px w-50px" name="org_read" type="checkbox" value="" id="org_read"
                                                                checked={config.accept_reservations} />
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="separator separator-dashed my-5"></div>
                                                <div className="d-flex flex-stack">
                                                    <div className="d-flex">
                                                        <div className="d-flex flex-column">
                                                            <span className="fs-5 text-dark fw-bold">Online</span>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex justify-content-end">
                                                        <label className="form-check form-switch form-switch-sm form-check-custom form-check-solid">
                                                            <input
                                                                onChange={(e) => {
                                                                    setConfig({ ...config, accept_online_reservations: e.target.checked });
                                                                    let configsToSave = {
                                                                        ...oldConfig,
                                                                        accept_online_reservations: e.target.checked
                                                                    };
                                                                    setOldConfig(configsToSave);
                                                                    saveConfigs(configsToSave);
                                                                }}
                                                                disabled={!config.accept_reservations}
                                                                className="form-check-input" name="slack" type="checkbox" value="1" id="kt_modal_connected_accounts_slack"
                                                                checked={config.accept_online_reservations && config.accept_reservations} />
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="separator separator-dashed my-5"></div>
                                                <div className="d-flex flex-stack">
                                                    <div className="d-flex">
                                                        <div className="d-flex flex-column">
                                                            <span className="fs-5 text-dark fw-bold">Telefone</span>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex justify-content-end">
                                                        <label className="form-check form-switch form-switch-sm form-check-custom form-check-solid">
                                                            <input
                                                                onChange={(e) => {
                                                                    setConfig({ ...config, accept_phone_reservations: e.target.checked });
                                                                    let configsToSave = {
                                                                        ...oldConfig,
                                                                        accept_phone_reservations: e.target.checked
                                                                    };
                                                                    setOldConfig(configsToSave);
                                                                    saveConfigs(configsToSave);
                                                                }}
                                                                disabled={!config.accept_reservations}
                                                                className="form-check-input" name="slack" type="checkbox" value="1" id="kt_modal_connected_accounts_slack"
                                                                checked={config.accept_phone_reservations && config.accept_reservations}
                                                            />
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed p-6 mt-6">
                                                <i className="ki-duotone ki-design-1 fs-2tx text-primary me-4"></i>
                                                <div className="d-flex flex-stack flex-grow-1">
                                                    <div className="fw-semibold">
                                                        <div className="fs-6 text-gray-700">As reservas por telefone utilizarão o número cadastrado no perfil do restaurante. Para alterar, acesse o perfil.</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-8 col-12">

                                    <div className="card mb-5">
                                        <div className="card-header">
                                            <div className="card-title fs-3 fw-bold">Configuração de Agendamento</div>
                                        </div>
                                        <div className="card-body p-9">
                                            <div className="row mb-6">
                                                <label className="col-lg-4 col-form-label fw-bold fs-6">Quantidade mínima de pessoas por agendamento</label>
                                                <div className="col-lg-8 fv-row">
                                                    <select
                                                        value={`${config.min_person_per_reservation}`}
                                                        onChange={(e) => {
                                                            setConfig({ ...config, min_person_per_reservation: parseInt(e.target.value) });
                                                        }}
                                                        name="state_id" className="form-select form-select-solid form-select-lg">
                                                        {Array.from({ length: 100 }, (_, i) => i + 1).map((item) => (
                                                            <option value={item}>{item} pessoa(s)</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="row mb-6">
                                                <label className="col-lg-4 col-form-label fw-bold fs-6">Quantidade máxima de pessoas por agendamento</label>
                                                <div className="col-lg-8 fv-row">
                                                    <select
                                                        value={`${config.max_person_per_reservation}`}
                                                        onChange={(e) => {
                                                            setConfig({
                                                                ...config, max_person_per_reservation: parseInt(e.target.value),
                                                                auto_accept_reservations_max: (config.auto_accept_reservations_max > parseInt(e.target.value)) ? parseInt(e.target.value) : config.auto_accept_reservations_max
                                                            });
                                                        }}
                                                        name="state_id" className="form-select form-select-solid form-select-lg">
                                                        {Array.from({ length: 100 }, (_, i) => i + 1).map((item) => (
                                                            <option value={item}>{item} pessoa(s)</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="row mb-6">
                                                <label className="col-lg-4 col-form-label fw-bold fs-6">Aprovação</label>
                                                <div className="col-lg-8 fv-row">
                                                    <select
                                                        defaultValue={`${config.auto_accept_reservations}`}
                                                        value={`${(config.auto_accept_reservations) ? '1' : '0'}`}
                                                        onChange={(e) => {
                                                            setConfig({ ...config, auto_accept_reservations: (e.target.value === '1') ? true : false });
                                                        }}
                                                        name="state_id" className="form-select form-select-solid form-select-lg">
                                                        <option value="0">

                                                            Manual (recomendado)

                                                        </option>
                                                        <option value="1">

                                                            Automático

                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                            {(config.auto_accept_reservations) && (
                                                <div className="row mb-6">
                                                    <label className="col-lg-4 col-form-label fw-bold fs-6">Aceitar automaticamente agendamentos até</label>
                                                    <div className="col-lg-8 fv-row">
                                                        <select
                                                            value={`${config.auto_accept_reservations_max}`}
                                                            onChange={(e) => {
                                                                setConfig({ ...config, auto_accept_reservations_max: parseInt(e.target.value) });
                                                            }}
                                                            name="state_id" className="form-select form-select-solid form-select-lg">
                                                            {Array.from({ length: config.max_person_per_reservation }, (_, i) => i + 1).map((item) => (
                                                                <option value={item}>{item} pessoa(s)</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            )}
                                            <div className="row mb-6">
                                                <label className="col-lg-4 col-form-label fw-bold fs-6">Intervalo entre agendamentos</label>
                                                <div className="col-lg-8 fv-row">
                                                    <select
                                                        defaultValue={`${config.reservations_interval}`}
                                                        value={`${config.reservations_interval}`}
                                                        onChange={(e) => {
                                                            setConfig({ ...config, reservations_interval: parseInt(e.target.value) });
                                                        }}
                                                        name="state_id" className="form-select form-select-solid form-select-lg">
                                                        <option value="10">

                                                            Em intervalos de 10 minutos

                                                        </option>
                                                        <option value="15">

                                                            Em intervalos de 15 minutos

                                                        </option>
                                                        <option value="20">

                                                            Em intervalos de 20 minutos

                                                        </option>
                                                        <option value="30">

                                                            Em intervalos de 30 minutos

                                                        </option>
                                                        <option value="60">

                                                            Em intervalos de 60 minutos

                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="row mb-6">
                                                <label className="col-lg-4 col-form-label fw-bold fs-6">Antecedência mínima</label>
                                                <div className="col-lg-8 fv-row">
                                                    <select
                                                        defaultValue={`${config.minimum_antecedence}`}
                                                        value={`${config.minimum_antecedence}`}
                                                        onChange={(e) => {
                                                            setConfig({ ...config, minimum_antecedence: parseInt(e.target.value) });
                                                        }}
                                                        name="state_id" className="form-select form-select-solid form-select-lg">
                                                        <option value="0">

                                                            Nenhum

                                                        </option>
                                                        <option value="60">

                                                            1 hora

                                                        </option>
                                                        <option value="120">

                                                            2 horas

                                                        </option>
                                                        <option value="240">

                                                            4 horas

                                                        </option>
                                                        <option value="480">

                                                            8 horas

                                                        </option>
                                                        <option value="720">

                                                            12 horas

                                                        </option>
                                                        <option value="1080">

                                                            18 horas

                                                        </option>
                                                        <option value="1440">

                                                            1 dia

                                                        </option>
                                                        <option value="2880">

                                                            2 dias

                                                        </option>
                                                        <option value="4320">

                                                            3 dias

                                                        </option>
                                                        <option value="10080">

                                                            1 semana

                                                        </option>
                                                        <option value="20160">

                                                            2 semanas

                                                        </option>
                                                        <option value="30240">

                                                            3 semanas

                                                        </option>
                                                        <option value="40320">

                                                            4 semanas

                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="row mb-6">
                                                <label className="col-lg-4 col-form-label fw-bold fs-6">Antecedência máxima</label>
                                                <div className="col-lg-8 fv-row">
                                                    <select
                                                        defaultValue={`${config.maximum_antecedence}`}
                                                        value={`${config.maximum_antecedence}`}
                                                        onChange={(e) => {
                                                            setConfig({ ...config, maximum_antecedence: parseInt(e.target.value) });
                                                        }}
                                                        name="state_id" className="form-select form-select-solid form-select-lg">
                                                        <option value="0">

                                                            Nenhum

                                                        </option>
                                                        <option value="60">

                                                            1 hora

                                                        </option>
                                                        <option value="120">

                                                            2 horas

                                                        </option>
                                                        <option value="240">

                                                            4 horas

                                                        </option>
                                                        <option value="480">

                                                            8 horas

                                                        </option>
                                                        <option value="720">

                                                            12 horas

                                                        </option>
                                                        <option value="1080">

                                                            18 horas

                                                        </option>
                                                        <option value="1440">

                                                            1 dia

                                                        </option>
                                                        <option value="2880">

                                                            2 dias

                                                        </option>
                                                        <option value="4320">

                                                            3 dias

                                                        </option>
                                                        <option value="10080">

                                                            1 semana

                                                        </option>
                                                        <option value="20160">

                                                            2 semanas

                                                        </option>
                                                        <option value="30240">

                                                            3 semanas

                                                        </option>
                                                        <option value="40320">

                                                            4 semanas

                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-footer d-flex justify-content-end py-6 px-9">
                                            <button
                                                onClick={(e) => {
                                                    setConfig({ ...oldConfig });
                                                }}
                                                type="button" className="btn btn-light btn-active-light-primary me-2">Cancelar</button>
                                            <button
                                                onClick={(e) => {
                                                    // Update config using OldConfig plus updated values and update OldConfigs
                                                    let configsToSave = {
                                                        ...oldConfig,
                                                        auto_accept_reservations: config.auto_accept_reservations,
                                                        auto_accept_reservations_max: config.auto_accept_reservations_max,
                                                        reservations_interval: config.reservations_interval,
                                                        minimum_antecedence: config.minimum_antecedence,
                                                        maximum_antecedence: config.maximum_antecedence,
                                                        min_person_per_reservation: config.min_person_per_reservation,
                                                        max_person_per_reservation: config.max_person_per_reservation,
                                                    };
                                                    setOldConfig(configsToSave);
                                                    saveConfigs(configsToSave);
                                                }}
                                                type="button" className="btn btn-primary" id="kt_project_settings_submit">Salvar</button>
                                        </div>
                                    </div>
                                    <div className="card mb-5">
                                        <div className="card-header">
                                            <h3 className="card-title align-items-start flex-column">
                                                <span className="card-label fw-bold text-dark">Política de Cancelamento</span>
                                            </h3>
                                        </div>
                                        <div className="card-body border-top p-9">
                                            <textarea
                                                onChange={(e) => {
                                                    setConfig({ ...config, cancellation_policy: e.target.value });
                                                }}
                                                name="description" className="form-control form-control-solid h-100px">{config.cancellation_policy}</textarea>
                                        </div>
                                        <div className="card-footer d-flex justify-content-end py-6 px-9">
                                            <button
                                                onClick={(e) => {
                                                    setConfig({ ...oldConfig });
                                                }}
                                                type="button" className="btn btn-light btn-active-light-primary me-2">Cancelar</button>
                                            <button
                                                onClick={(e) => {
                                                    // Update config using OldConfig plus updated values and update OldConfigs
                                                    let configsToSave = {
                                                        ...oldConfig,
                                                        cancellation_policy: config.cancellation_policy
                                                    };
                                                    setOldConfig(configsToSave);
                                                    saveConfigs(configsToSave);
                                                }}
                                                type="button" className="btn btn-primary" id="kt_project_settings_submit">Salvar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default ReservationsConfigPage;