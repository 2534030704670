import React, { useEffect, useState, FC } from "react";
import { ScheduleMetaModel } from "../../../models/Schedule";

interface Props {
    item: ScheduleMetaModel
    index: number
    translateDayOfWeek: (day: string) => string
    toggleEnabled: (index: number) => void
    setFrom: (index: number, v: string) => void
    setTo: (index: number, v: string) => void
    setMaxDelay: (index: number, v: string) => void
}

const ReservationServiceDay: FC<Props> = ({ item, index, translateDayOfWeek, toggleEnabled, setFrom, setTo, setMaxDelay }) => {

    const generateTimeOptions = () => {
        const times = [];
        for (let hour = 0; hour < 24; hour++) {
            for (let minute = 0; minute < 60; minute += 30) {
                const formattedHour = hour.toString().padStart(2, '0');
                const formattedMinute = minute.toString().padStart(2, '0');
                times.push(`${formattedHour}:${formattedMinute}`);
            }
        }
        return times;
    };

    const handleStartTimeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedStartTime = e.target.value;
        setStartTime(selectedStartTime);
        setFrom(index, selectedStartTime);

        // Filter end times to be only after the selected start time
        const filteredEndTimes = allTimes.filter((time) => time > selectedStartTime);
        setEndTimes(filteredEndTimes);

        // Set the end time to the first available option after filtering
        if (filteredEndTimes.length > 0) {
            setEndTime(filteredEndTimes[0]);
            setTo(index, filteredEndTimes[0]);
        } else {
            setEndTime('');
            setTo(index, '');
        }
    };

    const handleEndTimeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setEndTime(e.target.value);
        setTo(index, e.target.value);
    };

    const allTimes = generateTimeOptions();
    const [startTime, setStartTime] = useState(item.from ? item.from : "");
    const [endTimes, setEndTimes] = useState(allTimes.slice(1));
    const [endTime, setEndTime] = useState(item.to ? item.to : "");

    return (<>
        <div className="border rounded border-gray-200 p-5 mb-3">
            <div className="d-flex justify-content-between align-items-center w-100 mt-auto mb-2">
                <span className="text-dark fw-bolder fs-5">{translateDayOfWeek(item.day_of_week)}</span>
                <div className="form-check form-switch form-check-custom">
                    <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault"
                        checked={item.enabled}
                        onClick={(e) => toggleEnabled(index)}
                    />
                </div>
            </div>
            <div className="separator separator-dashed my-3"></div>
            <div className="d-flex justify-content-between align-items-center w-100 mt-auto mb-2">
                <span className="text-dark fw-normal">Horário</span>
                <div className="d-flex">
                    <select
                        required={item.enabled}
                        disabled={!item.enabled}
                        value={startTime} onChange={handleStartTimeChange}
                        className="form-select form-select-solid select2-hidden-accessible form-select-sm me-3 w-90px" data-control="select2" data-placeholder="De:" data-hide-search="true" data-select2-id="select2-data-1-pr05" aria-hidden="true">
                        <option value="" disabled selected>De:</option>
                        {allTimes.map((time) => (
                            <option key={time} value={time}>
                                {time}
                            </option>
                        ))}
                    </select>
                    <select
                        required={item.enabled}
                        value={endTime} onChange={handleEndTimeChange} disabled={endTimes.length === 0 || !item.enabled}
                        className="form-select form-select-solid select2-hidden-accessible form-select-sm w-90px" data-control="select2" data-placeholder="Até:" data-hide-search="true" data-select2-id="select2-data-4-oxmt" aria-hidden="true">
                        <option value="" disabled selected>Até:</option>
                        {endTimes.map((time) => (
                            <option key={time} value={time}>
                                {time}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            <div className="separator separator-dashed border-gray-200 my-3"></div>
            <div className="d-flex justify-content-between align-items-center w-100 mt-auto mb-2">
                <span className="text-dark fw-normal">Tolerância</span>
                <div className="d-flex">
                    <select
                        required={item.enabled}
                        disabled={!item.enabled}
                        value={item.max_delay}
                        onChange={(e) => setMaxDelay(index, e.target.value)}
                        className="form-select form-select-solid select2-hidden-accessible form-select-sm w-90px" data-control="select2" data-placeholder="10 min" data-hide-search="true" data-select2-id="select2-data-7-6t1b" aria-hidden="true">
                        <option value=""></option>
                        <option value="10">10 min</option>
                        <option value="20">20 min</option>
                        <option value="30">30 min</option>
                        <option value="40">40 min</option>
                        <option value="50">50 min</option>
                        <option value="60">60 min</option>
                    </select>
                </div>
            </div>
        </div>
    </>);
}

export default ReservationServiceDay;