import React, { useEffect, useState, FC } from "react";

// Custom hooks
import { useAuth } from "../../hooks/useAuth";
import { useAPI } from "../../hooks/useAPI";
import { useFormik } from "formik";
import * as Yup from "yup";
import { CustomerModel } from "../../models/CustomerModel";
import clsx from "clsx";
import InputMask from "react-input-mask";
import Error from "../../components/messages/Error";
import { useCustomer } from "../../hooks/useCustomer";
import moment from "moment";
import { useReservation } from "../../hooks/useReservation";
import { BusinessBranchModel } from "../../models/BusinessModel";
import { ReservationModel, ReservationService } from "../../models/Reservation";
import { useHelper } from "../../hooks/useHelper";
import { ScheduleMetaContext, ScheduleMetaContextProvider } from "../../providers/ScheduleMetaContextProvider";
import { DefaultScheduleMeta, ScheduleMetaModel } from "../../models/Schedule";
import ReservationServiceDay from "./components/ReservationServiceDay";

interface Props {
    onSave: (data: any) => void,
    business_branch: BusinessBranchModel,
    service?: ReservationService,
    services: ReservationService[],
    onClose: () => void
    edit: boolean
    show?: boolean
}

const ReservationServiceClosingModal: FC<Props> = ({ onSave, business_branch, service, onClose, edit, show = true, services }) => {
    const { api } = useAPI();
    const [loading, setLoading] = useState(true);
    const [meta, setMeta] = useState<Array<ScheduleMetaModel>>((service) ? service.meta : DefaultScheduleMeta);
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [saving, setSaving] = useState(false);
    const [data, setData] = useState<ReservationService>((service) ? service : {
        id: -1,
        name: "",
        max_persons: 1,
        type: "CLO",
        meta: DefaultScheduleMeta,
        date_start: "",
        date_end: "",
        time_start: "",
        time_end: "",
        max_delay: ""
    })

    // Close modal
    const closeModal = () => {
        onClose();
    };

    const handleSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault();

        setSaving(true);
        setShowError(false);
        const req = (!service) ?
            api()
                .post(`/api/business/${business_branch.business_id}/branch/${business_branch.id}/reservation-service`, data, true)
            :
            api()
                .put(`/api/business/${business_branch.business_id}/branch/${business_branch.id}/reservation-service/${service.id}`, data, true);

        req.then((resp) => {
            setSaving(false);
            if (resp.data.success === true) {
                onSave(resp.data.service);
            } else if(resp.data.conflict) {
                setShowError(true);
                setErrorMessage(`Existe um conflito com "${resp.data.conflicts[0].name} (#${resp.data.conflicts[0].id})".`);
                document.getElementById("kt_modal_add_user_scroll")?.scrollTo({ top: 0, behavior: "smooth" });
            }
        })
            .catch((err) => {
                setSaving(false);
                setShowError(true);
                setErrorMessage('Houve um erro! Por favor, tente novamente.');
                document.getElementById("kt_modal_add_user_scroll")?.scrollTo({ top: 0, behavior: "smooth" });
            });
    }

    return (
        <div>
            <div className={"modal fade" + ((show) ? ' show' : '')}>
                <div className="modal-dialog modal-dialog-centered mw-500px">
                    <div className="modal-content">
                        <div className="modal-header" id="kt_modal_add_user_scroll">
                            <h2 className="fw-bolder">Período de Fechamento</h2>
                            <div
                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                onClick={closeModal}
                            >
                                <span className="svg-icon svg-icon-2x">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24"
                                        height="24" viewBox="0 0 24 24" fill="none">
                                        <rect opacity="0.5" x="6" y="17.3137" width="16"
                                            height="2" rx="1"
                                            transform="rotate(-45 6 17.3137)"
                                            fill="black"></rect>
                                        <rect x="7.41422" y="6" width="16" height="2"
                                            rx="1" transform="rotate(45 7.41422 6)"
                                            fill="black"></rect>
                                    </svg>
                                </span>
                            </div>
                        </div>
                        <div className="modal-body p-9 pt-3">
                            <form onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className={"alert alert-danger" + ((showError) ? "" : " d-none")}>
                                        <div className="d-flex flex-column">
                                            <h4 className="mb-1 text-dark">Erro</h4>
                                            <span>{errorMessage}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed text-center mt-6 mb-6 px-9 py-6">
                                    <div className="d-flex flex-stack flex-grow-1">
                                        <div className="fw-semibold">
                                            <div className="fs-6 text-gray-700">Insira o período e selecione o serviço que ficará indisponível.</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row g-3 mt-5">
                                    <div className="col-6">
                                        <div className="input-group input-group-solid">
                                            <span className="input-group-text text-dark fw-normal fs-7 border" id="basic-addon1">De:</span>
                                            <input
                                                required={true}
                                                value={data.date_start}
                                                onChange={(e) => {
                                                    // Ensure end date is not earlier than the start date
                                                    if (data.date_end && new Date(e.target.value) > new Date(data.date_end)) {
                                                        setData({ ...data, date_end: e.target.value, date_start: e.target.value });
                                                    } else {
                                                        setData({ ...data, date_start: e.target.value });
                                                    }
                                                }}
                                                type="date" className="form-control fs-7 border" />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="input-group input-group-solid">
                                            <span className="input-group-text text-dark fw-normal fs-7 border" id="basic-addon1">Até:</span>
                                            <input
                                                required={true}
                                                value={data.date_end}
                                                onChange={(e) => {
                                                    setData({ ...data, date_end: e.target.value })
                                                }}
                                                min={data.date_start}
                                                type="date" className="form-control fs-7 border" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-5">
                                    <div className="col-12">
                                        <select
                                            required={true}
                                            value={data.service_id}
                                            onChange={(e) => {
                                                setData({ ...data, service_id: parseInt(e.target.value) })
                                            }}
                                            className="form-select form-select-solid select2-hidden-accessible">
                                            <option value="">Selecione</option>
                                            {services.map((item) => (
                                                <option value={item.id}>{item.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>

                                <div className="text-center mt-8">
                                    <button type="button" className="btn btn-light me-3" data-bs-dismiss="modal" onClick={closeModal}>Fechar</button>
                                    <button type="submit" className="btn btn-success"
                                        data-kt-indicator={(saving) ? "on" : "off"}
                                        disabled={saving}
                                    >
                                        <span className="indicator-label">Confirmar</span>
                                        <span className="indicator-progress">Salvando...
                                            <span
                                                className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ReservationServiceClosingModal;