import React, { FC, useEffect, useState } from "react";
import { BusinessBranchModel } from "../../models/BusinessModel";
import { useConfig } from "../../hooks/useConfig";
import Loading from "../../components/Loading";
import { useAPI } from "../../hooks/useAPI";
import ReactGA from 'react-ga4';
import ReactPixel from 'react-facebook-pixel';
import { useStateList } from "../../hooks/useStateList";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import ReservationDataForm from "./components/ReservationDataForm";
import ReservationDataConfirm from "./components/ReservationDataConfirm";
import { ReservationModel } from "../../models/Reservation";
import ReservationDataSuccess from "./components/ReservationDataSuccess";
import { Link, useHistory } from "react-router-dom";

interface Props {
    business_branch: BusinessBranchModel
}

const ReservationPage: FC<Props> = ({ business_branch }) => {
    const { getConfig } = useConfig();
    const { api } = useAPI();
    const { getStateShort } = useStateList();
    const [adrress, setAddress] = useState("");
    const [step, setStep] = useState(0);
    const [reservation, setReservation] = useState<ReservationModel | undefined>(undefined);
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    const { executeRecaptcha } = useGoogleReCaptcha();

    const handleSubmit = async (data: any) => {
        if (!executeRecaptcha) {
            return;
        }
        setLoading(true);
        const token = await executeRecaptcha('research');
        // Save research
        api()
            .post(`/api/business/${business_branch.business_id}/branch/${business_branch.id}/reservation`, { ...reservation, ...data, "recaptcha-token": token })
            .then((resp) => {
                setLoading(false);
                if (resp.data.success === true) {
                    setStep(2);
                }

                if (resp.data.error === true) {
                    alert(resp.data.msg);
                }
            })
            .catch((err) => {
                setLoading(false);
                alert('Houve um erro! Por favor, tente novamente.')
            });
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        let root = document.getElementById("root");
        if (root !== null)
            root.scrollTo(0, 0);
    }, [step]);

    useEffect(() => {
        if (business_branch.google_analytics !== null) {
            ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
        }
        if (business_branch.facebook_pixel !== null) {
            ReactPixel.pageView();
        }

        if (!business_branch.reservation_config?.accept_online_reservations) {
            history.push(`/perfil/${business_branch.slug}`);
        }
    }, []);

    useEffect(() => {
        try {
            document.title = `${business_branch.branch} ${business_branch.name} - nexp digital`;
            setAddress(`${business_branch.address.street}, ${business_branch.address.number} - ${business_branch.address.district} , ${business_branch.address.city.name} ${getStateShort(business_branch.address.city.state_id)}, ${business_branch.address.zipcode}`);
        } catch (e) { }
    }, [business_branch]);

    useEffect(() => {
        document.getElementById("body-custom")?.classList.add('bg-light');
    }, [business_branch]);

    return (
        <>
            {loading && (<Loading fullscreen={true} />)}
            <div className="container mw-500px p-0">
                <div className="position-relative">
                    <div className="z-index-3 d-flex position-absolute justify-content-between p-5 w-100">
                        {step === 0 && (
                            <>
                                <a className="elementor-icon" href={`/perfil/${business_branch.slug}`}>
                                    <span className="svg-icon svg-icon-1 text-white" data-bs-toggle="tooltip" title="" data-bs-original-title="Encaminhar Cardápio">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
                                        </svg>
                                    </span>
                                </a>
                                <span className="fs-7 d-block fw-bolder mt-1 text-white text-uppercase">FAÇA SUA RESERVA</span>
                                <a className="elementor-icon"
                                    target="_blank"
                                    href={`https://api.whatsapp.com/send?text=Reservas ${business_branch.branch} - ${business_branch.name} ${getConfig('SHORT_URL')}/reserva/${business_branch.slug}`}
                                >
                                    <span className="svg-icon svg-icon-3 text-white" data-bs-toggle="tooltip" title="" data-bs-original-title="Encaminhar Cardápio">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-send" viewBox="0 0 16 16">
                                            <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576 6.636 10.07Zm6.787-8.201L1.591 6.602l4.339 2.76 7.494-7.493Z"></path>
                                        </svg>
                                    </span>
                                </a>
                            </>
                        )}
                        {step === 1 && (
                            <>
                                <a className="elementor-icon"
                                    href=""
                                    onClick={(e) => {
                                        setStep(0);
                                        e.preventDefault();
                                    }}
                                >
                                    <span className="svg-icon svg-icon-1 text-white" data-bs-toggle="tooltip" title="" data-bs-original-title="Encaminhar Cardápio">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
                                        </svg>
                                    </span>
                                </a>
                            </>
                        )}

                    </div>
                    <div className="position-absolute bg-dark w-100 h-175px bg-opacity-50"></div>
                </div>
                <div className="d-flex-row p-0 h-175px overflow-hidden"
                    style={{
                        "backgroundImage": `url(${business_branch.header_image_url})`,
                        "backgroundSize": "cover",
                        "backgroundRepeat": "no-repeat",
                        "backgroundPosition": "center"
                    }}>
                </div>
            </div>

            <div className="container mw-500px mt-n15">

                <div className="card rounded-start-5" >
                    <div className="card-body py-0">
                        <div className="d-flex justify-content-center">
                            <div className="symbol symbol-100px rounded shadow" style={{ "marginTop": "-50px" }} data-kt-menu-trigger="click" data-kt-menu-attach="parent" data-kt-menu-placement="bottom-end">
                                <img className="rounded-3" src={(business_branch.avatar_url !== null) ? business_branch.avatar_url : undefined} alt="user" />
                                <div className="position-absolute translate-middle bottom-0 start-100 mb-1 h-25px w-25px">
                                    <span className="svg-icon svg-icon-2x svg-icon-primary">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24">
                                            <path d="M10.0813 3.7242C10.8849 2.16438 13.1151 2.16438 13.9187 3.7242V3.7242C14.4016 4.66147 15.4909 5.1127 16.4951 4.79139V4.79139C18.1663 4.25668 19.7433 5.83365 19.2086 7.50485V7.50485C18.8873 8.50905 19.3385 9.59842 20.2758 10.0813V10.0813C21.8356 10.8849 21.8356 13.1151 20.2758 13.9187V13.9187C19.3385 14.4016 18.8873 15.491 19.2086 16.4951V16.4951C19.7433 18.1663 18.1663 19.7433 16.4951 19.2086V19.2086C15.491 18.8873 14.4016 19.3385 13.9187 20.2758V20.2758C13.1151 21.8356 10.8849 21.8356 10.0813 20.2758V20.2758C9.59842 19.3385 8.50905 18.8873 7.50485 19.2086V19.2086C5.83365 19.7433 4.25668 18.1663 4.79139 16.4951V16.4951C5.1127 15.491 4.66147 14.4016 3.7242 13.9187V13.9187C2.16438 13.1151 2.16438 10.8849 3.7242 10.0813V10.0813C4.66147 9.59842 5.1127 8.50905 4.79139 7.50485V7.50485C4.25668 5.83365 5.83365 4.25668 7.50485 4.79139V4.79139C8.50905 5.1127 9.59842 4.66147 10.0813 3.7242V3.7242Z" fill="#00A3FF"></path>
                                            <path className="permanent" d="M14.8563 9.1903C15.0606 8.94984 15.3771 8.9385 15.6175 9.14289C15.858 9.34728 15.8229 9.66433 15.6185 9.9048L11.863 14.6558C11.6554 14.9001 11.2876 14.9258 11.048 14.7128L8.47656 12.4271C8.24068 12.2174 8.21944 11.8563 8.42911 11.6204C8.63877 11.3845 8.99996 11.3633 9.23583 11.5729L11.3706 13.4705L14.8563 9.1903Z" fill="white"></path>
                                        </svg>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card-body text-center pb-5">
                        <span className="fs-8 d-block fw-bolder my-2 text-black-50 text-uppercase mt-0">Seja bem-vindo(a)</span>
                        <h1 className="fs-1 primary-brand-color mb-0 fw-normal">{business_branch.branch}</h1>
                        <h2 className="fs-1 primary-brand-color fw-bolder mb-1">{business_branch.name}</h2>
                    </div>

                    <div className="separator separator-dotted border-2 w-75 m-auto"></div>
                    <label>
                        <span className="section-ticket dots">
                            <span className="bg-light"></span>
                            <span className="bg-light"></span>
                        </span>
                    </label>
                    {step === 0 && (<ReservationDataForm
                        business_branch={business_branch}
                        reservation={reservation}
                        onSubmit={(data) => {
                            setReservation(data);
                            setStep(1);
                        }} />)}
                    {step === 1 && reservation !== undefined && (<ReservationDataConfirm
                        business_branch={business_branch}
                        reservation={reservation}
                        onBack={() => {
                            setStep(0);
                        }}
                        onSubmit={handleSubmit} />)}
                    {step === 2 && (<ReservationDataSuccess business_branch={business_branch} />)}
                </div>
                <div className="d-flex-row w-100 mt-5 text-center">
                    <h1 className="fs-4 primary-brand-color mb-1 fw-bolder">{business_branch.name}</h1>
                    <p className="fs-5 text-gray-600 mb-0 mx-7">{adrress}</p>
                </div>
            </div>
        </>
    );
}

export default ReservationPage;