import React, {FC} from "react";
import {BusinessBranchModel} from "../../../models/BusinessModel";
import { ReservationModel } from "../../../models/Reservation";

interface Props {
    business_branch: BusinessBranchModel
    reservation: ReservationModel
    name: string
}

const ReservationAdminRSVPFeedback: FC<Props> = ({business_branch, reservation, name}) => {
    
    return (
        <>
            <div className="container app-wrapper mw-500px">
				
                <div className="card rounded-3 py-5 mt-5">
                    
                    <div className="card-body text-center pb-0">
                        <div className="swal2-icon swal2-success swal2-icon-show d-flex"><div className="swal2-success-circular-line-left" style={{'backgroundColor': 'rgb(255, 255, 255)'}}></div>
                          <span className="swal2-success-line-tip"></span> <span className="swal2-success-line-long"></span>
                          <div className="swal2-success-ring"></div> <div className="swal2-success-fix" style={{'backgroundColor': 'rgb(255, 255, 255)'}}></div>
                          <div className="swal2-success-circular-line-right" style={{'backgroundColor': 'rgb(255, 255, 255)'}}></div>
                        </div>
                    </div>
                    <div className="card-body text-center pb-20">
                        <h1 className="fs-2hx text-success mb-0 fw-bolder mb-10"><span style={{textTransform: 'capitalize'}}>{name}</span>, sua presença foi confirmada com sucesso!</h1>
                        {/*<div className="mx-auto mt-10 text-cente">
                            <button className="btn btn-sm btn-light border border-gray-300 mx-1 shadow-sm" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-start" data-kt-menu-overflow="true">
                            Adicionar ao Calendário<i className="bi bi-calendar3 ms-3 text-mute"></i>
                            </button>
                            <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px" data-kt-menu="true">
                                <div className="separator mb-3 opacity-75"></div>
                                <div className="menu-item px-3">
                                    <a href="#" className="menu-link px-3">Google</a>
                                </div>
                                <div className="menu-item px-3">
                                    <a href="#" className="menu-link px-3">Apple</a>
                                </div>
                                <div className="menu-item px-3">
                                    <a href="#" className="menu-link px-3">Outlook</a>
                                </div>
                                <div className="menu-item px-3">
                                    <a href="#" className="menu-link px-3">Yahoo</a>
                                </div>
                            </div>
                        </div>*/}
                    </div>
                </div>
                
            </div>
        </>
    );
}

export default ReservationAdminRSVPFeedback;