import React, {FC, useEffect, useReducer} from "react";
import {DefaultScheduleMeta, ScheduleMetaModel, translateDay} from "../models/Schedule";
import ScheduleMetaReducer from "../reducers/ScheduleMetaReducer";


const DefaultScheduleContext = {
    meta: DefaultScheduleMeta,
    toggleEnabled: (index: number) => {},
    translateDayOfWeek: (dayOfWeek: string) : any => {},
    setFrom: (index: number, from: string) => {},
    setTo: (index: number, to: string) => {},
    setMaxDelay: (index: number, max_delay: string | number) => {}
}
const ScheduleMetaContext = React.createContext(DefaultScheduleContext);

interface Props {
    meta?: Array<ScheduleMetaModel>
}
const ScheduleMetaContextProvider: FC<Props> = ({children, meta}) => {
    const toggleEnabled = (index: number) => {
        dispatch({type:'toggleEnabled', index: index});
    }

    const setFrom = (index: number, from: string) => {
        dispatch({type:'setFrom', index: index, from: from});
    }

    const setTo = (index: number, to: string) => {
        dispatch({type:'setTo', index: index, to: to});
    }

    const setMaxDelay = (index: number, max_delay: string | number) => {
        dispatch({type:'setMaxDelay', index: index, max_delay: max_delay});
    }    

    const [scheduleMeta, dispatch] = useReducer(ScheduleMetaReducer,
    {...DefaultScheduleContext,
        meta: meta,
        toggleEnabled: toggleEnabled,
        translateDayOfWeek: translateDay,
        setFrom: setFrom,
        setTo: setTo,
        setMaxDelay: setMaxDelay
    });

    return (
        <ScheduleMetaContext.Provider value={scheduleMeta}>
            {children}
        </ScheduleMetaContext.Provider>
    );
}

export {ScheduleMetaContextProvider, ScheduleMetaContext}