import { BusinessBranchModel } from "../models/BusinessModel";
import { useAPI } from "./useAPI";

export function useReservation() {
  const { api } = useAPI();

  const getServicesForDate = async (branch: BusinessBranchModel, date: string): Promise<Array<string> | boolean> => {
    return new Promise((resolve) => {
      api()
        .get(`/api/business/${branch.business_id}/branch/${branch.id}/reservation-service/${date}`)
        .then((resp) => resolve(resp.data))
        .catch((err) => resolve(false));
    });
  }

  const getReasons = () => {
    return [
      { 'label': 'Aniversário', 'value': "1" },
      { 'label': 'Aniversário de Casamento', 'value': "2" },
      { 'label': 'Bodas', 'value': "3" },
      { 'label': 'Batizado', 'value': "4" },
      { 'label': 'Casamento', 'value': "5" },
      { 'label': 'Confraternização da Empresa', 'value': "6" },
      { 'label': 'Outros', 'value': "7" }
    ];
  }

  return {
    getServicesForDate,
    getReasons
  };
}
