import React, { FC, useContext, useEffect, useState } from "react";
import { PageProps } from "../../models/Page";
import { useAPI } from "../../hooks/useAPI";
import Loading from "../../components/Loading";
import ErrorLoading from "../../components/ErrorLoading";
import { useConfig } from "../../hooks/useConfig";
import clsx from "clsx";
import { PaginationReservations, ReservationAvailability, ReservationModel, ReservationService, ReservationStats } from "../../models/Reservation";
import ReservationAddModal from "./ReservationAddModal";
import { useHelper } from "../../hooks/useHelper";
import ReservationRow from "./ReservationRow";
import Success from "../../components/messages/Success";
import Confirm from "../../components/messages/Confirm";
import ConfirmModal from "./components/ConfirmModal";
import moment from "moment";
import ReservationServiceModal from "./ReservationServiceModal";
import { ScheduleMetaModel, translateDay } from "../../models/Schedule";
import ReservationServiceClosingModal from "./ReservationServiceClosingModal";

const ReservationsServicesPage: FC<PageProps> = ({ updateToolbar, business_branch }) => {
    const [showAddModal, setShowAddModal] = useState(false);

    useEffect(() => {
        updateToolbar({
            title: "Disponibilidade",
            breadcrumbs: [
                {
                    title: "Agendamento",
                    path: '#',
                    isSeparator: false,
                    isActive: false,
                },
                {
                    title: '',
                    path: '',
                    isSeparator: true,
                    isActive: false,
                },
            ],
            buttons: []
        });
        loadServices();
    }, []);

    const [loading, setLoading] = useState(true);
    const [errorLoading, setErrorLoading] = useState(false);
    const [services, setServices] = useState<ReservationService[] | undefined>(undefined);
    const { api } = useAPI();
    const [saveSuccess, setSaveSuccess] = useState(false);
    const [serviceToEdit, setServiceToEdit] = useState<ReservationService | undefined>(undefined);
    const [serviceToDelete, setServiceToDelete] = useState<ReservationService | undefined>(undefined);
    const [showDeleteSuccess, setShowDeleteSuccess] = useState(false);

    const [serviceClosingToEdit, setServiceClosingToEdit] = useState<ReservationService | undefined>(undefined);
    const [showAddClosingModal, setShowAddClosingModal] = useState(false);

    const loadServices = () => {
        setLoading(true);
        setErrorLoading(false);
        api().get(`/api/business/${business_branch.business_id}/branch/${business_branch.id}/reservation-service`).then((resp) => {
            setLoading(false);
            setServices(resp.data);
        }).catch(() => {
            setLoading(false);
            setErrorLoading(true);
        });
    }

    const getTypeBadge = (type: string) => {
        switch (type) {
            case "UNI":
                return (<span className="badge badge-light-success fw-bold me-auto px-4 py-3">Único</span>);
            case "REC":
                return (<span className="badge badge-light-primary fw-bold me-auto px-4 py-3">Recorrente</span>);
            case "CLO":
                return (<span className="badge badge-light-danger fw-bold me-auto px-4 py-3">Fechamento</span>);
        }

        return "";
    }

    const formatDaysOfWeek = (meta: ScheduleMetaModel[]) => {
        let days = [];
        for (let i in meta) {
            if (meta[i].enabled) {
                days.push(translateDay(meta[i].day_of_week).slice(0, 3));
            }
        }

        return days.join(", ");
    }


    return (
        <>
            {serviceToDelete && (
                <Confirm
                    message={'Deseja realmente excluir esse registro?'}
                    onConfirm={() => {
                        setServiceToDelete(undefined);
                        setLoading(true);
                        api()
                            .del(`/api/business/${business_branch.business_id}/branch/${business_branch.id}/reservation-service/${serviceToDelete.id}`)
                            .then((resp) => {
                                setLoading(false);
                                if (resp.data.success) {
                                    setShowDeleteSuccess(true);
                                } else {
                                    alert('Houve um erro, tente novamente.');
                                }
                            })
                            .catch(() => {
                                setLoading(false);
                                alert('Houve um erro, tente novamente.')
                            });
                    }}
                    onClose={() => {
                        setServiceToDelete(undefined);
                    }}
                />
            )}
            {showDeleteSuccess && (
                <Success
                    message={"Registro excluído com sucesso!"}
                    onClick={() => {
                        setShowDeleteSuccess(false);
                        loadServices();
                    }}
                />
            )}
            {(showAddModal || serviceToEdit !== undefined) && (<ReservationServiceModal
                key={`${(new Date()).getTime()}`}
                onClose={() => {
                    setShowAddModal(false);
                    setServiceToEdit(undefined);
                }}
                business_branch={business_branch}
                onSave={(reservation) => {
                    setShowAddModal(false);
                    setSaveSuccess(true);
                    setServiceToEdit(undefined);
                }}
                edit={false}
                show={true}
                service={serviceToEdit}
            />)}
            {(showAddClosingModal || serviceClosingToEdit !== undefined) && services && (<ReservationServiceClosingModal
                key={`${(new Date()).getTime()}`}
                services={services}
                onClose={() => {
                    setShowAddClosingModal(false);
                    setServiceClosingToEdit(undefined);
                }}
                business_branch={business_branch}
                onSave={(reservation) => {
                    setShowAddClosingModal(false);
                    setSaveSuccess(true);
                    setServiceClosingToEdit(undefined);
                }}
                edit={false}
                show={true}
                service={serviceClosingToEdit}
            />)}
            {errorLoading && (<ErrorLoading onTryAgain={() => {
                loadServices();
            }} />)}
            {saveSuccess && (
                <Success
                    message={"Disponibilidade salva com sucesso!"}
                    onClick={() => {
                        setSaveSuccess(false);
                        loadServices();
                    }}
                />
            )}

            {loading && (
                <Loading fullscreen={true} />
            )}
            {services && (
                <div className="d-flex flex-column flex-column-fluid mb-7">
                    <div id="kt_app_toolbar" className="app-toolbar py-3 py-lg-6">
                        <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
                            <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                                <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Disponibilidade</h1>
                            </div>
                            <div className="d-flex align-items-center gap-2 gap-lg-3">
                                <a
                                    onClick={() => setShowAddModal(true)}
                                    href="#" className="btn btn-sm fw-bold btn-primary" data-bs-original-title="Nova Disponibilidade" aria-label="Aprovar reserva" data-bs-toggle="modal" data-bs-target="#kt_modal_novo"><i className="bi bi-plus-lg fs-3 p-0"></i></a>
                                <a
                                    onClick={() => setShowAddClosingModal(true)}
                                    href="#" className="btn btn-sm fw-bold btn-danger" data-bs-original-title="Nova Disponibilidade" aria-label="Aprovar reserva" data-bs-toggle="modal" data-bs-target="#kt_modal_fechamento"><i className="bi bi-dash-lg p-0"></i></a>
                            </div>
                        </div>
                    </div>
                    <div id="kt_app_content" className="app-content flex-column-fluid">
                        <div id="kt_app_content_container" className="app-container container-xxl">
                            <div className="row g-6 g-xl-9">
                                {services.map((service) => (
                                    <div className="col-md-6 col-xl-4">
                                        <div className="card border-hover-primary">
                                            <div className="card-header border-0 p-7 pb-0">
                                                <h3 className="card-title align-items-start flex-column">
                                                    <span className="card-label fw-bold text-dark">{service.name} (#{service.id})</span>
                                                </h3>
                                                <div className="card-toolbar">
                                                    {getTypeBadge(service.type)}
                                                </div>
                                            </div>
                                            <div className="card-body p-7">
                                                <div className="d-flex flex-stack">
                                                    <div className="text-gray-500 flex-grow-1 me-4">Período:</div>
                                                    <div className="d-flex align-items-senter">
                                                        <i className="ki-duotone ki-arrow-up-right fs-2 text-success me-2">
                                                            <span className="path1"></span>
                                                            <span className="path2"></span>
                                                        </i>
                                                        <div className="fw-bolder text-gray-700 text-end" style={{ textTransform: 'capitalize' }}>
                                                            {(service.type !== "REC") ?
                                                                `${moment(service.date_start).format('DD MMM, YYYY')} - ${moment(service.date_end).format('DD MMM, YYYY')}`
                                                                : "---"}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="separator separator-dashed my-3"></div>
                                                <div className="d-flex flex-stack">
                                                    <div className="text-gray-500 flex-grow-1 me-4">Horário:</div>
                                                    <div className="d-flex align-items-senter">
                                                        <i className="ki-duotone ki-arrow-up-right fs-2 text-success me-2">
                                                            <span className="path1"></span>
                                                            <span className="path2"></span>
                                                        </i>
                                                        <div className="fw-bolder text-gray-700 text-end">
                                                            {service.type !== "CLO" && (
                                                                <>{service.time_start}h - {service.time_end}h</>
                                                            )}
                                                            {service.type === "CLO" && (
                                                                <>---</>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="separator separator-dashed my-3"></div>
                                                <div className="d-flex flex-stack">
                                                    <div className="text-gray-500 flex-grow-1 me-4">Chegada até:</div>
                                                    <div className="d-flex align-items-senter">
                                                        <i className="ki-duotone ki-arrow-up-right fs-2 text-success me-2">
                                                            <span className="path1"></span>
                                                            <span className="path2"></span>
                                                        </i>
                                                        {service.type !== "CLO" && (
                                                            <div className="fw-bolder text-gray-700 text-end">{moment('2020-01-01 ' + service.time_end).add(service.max_delay, 'minutes').format('HH:mm')}h</div>
                                                        )}
                                                        {service.type === "CLO" && (
                                                            <div className="fw-bolder text-gray-700 text-end">---</div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="separator separator-dashed my-3"></div>
                                                <div className="d-flex flex-stack">
                                                    <div className="text-gray-500 flex-grow-1 me-4">Dias das Semana:</div>
                                                    <div className="d-flex align-items-senter">
                                                        {service.type !== "CLO" && (
                                                            <div className="fw-bolder text-gray-700 text-end">{formatDaysOfWeek(service.meta)}</div>
                                                        )}
                                                        {service.type === "CLO" && (
                                                            <div className="fw-bolder text-gray-700 text-end">---</div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="separator separator-dashed my-3"></div>
                                                <div className="d-flex flex-stack">
                                                    <div className="text-gray-500 flex-grow-1 me-4">Pax total:</div>
                                                    <div className="d-flex align-items-senter">
                                                        <div className="fw-bolder text-gray-700 text-xxl-end">{(service.type !== "CLO") ? service.max_persons : "---"}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-footer p-7 text-end">
                                                <a
                                                    onClick={(e) => {
                                                        if (service.type !== "CLO") {
                                                            setServiceToEdit(service)
                                                        } else {
                                                            setServiceClosingToEdit(service)
                                                        }
                                                    }}
                                                    href="#" className="btn btn-icon btn-bg-light btn-active-color-primary text-gray-500 btn-sm me-1"><i className="bi bi-pencil-square"></i></a>
                                                <a
                                                    onClick={(e) => setServiceToDelete(service)}
                                                    href="#" className="btn btn-icon btn-bg-light btn-active-color-primary text-gray-500 btn-sm"><i className="bi bi-trash"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default ReservationsServicesPage;