export interface ScheduleModel {
    id: number
    type: string
    meta: Array<ScheduleMetaModel>
}

export interface ScheduleMetaModel {
    id?: number
    schedule_id?: number
    day_of_week: string
    enabled: boolean
    from: string
    to: string
    max_delay?: string | number
}

const ScheduleMeta: ScheduleMetaModel = {
    day_of_week: 'MON',
    enabled: true,
    from: '',
    to: '',
    max_delay: ''
};
export const DefaultScheduleMeta = [
    {
        ...ScheduleMeta,
        day_of_week: 'MON'
    },
    {
        ...ScheduleMeta,
        day_of_week: 'TUE'
    },
    {
        ...ScheduleMeta,
        day_of_week: 'WED'
    },
    {
        ...ScheduleMeta,
        day_of_week: 'THU'
    },
    {
        ...ScheduleMeta,
        day_of_week: 'FRI'
    },
    {
        ...ScheduleMeta,
        day_of_week: 'SAT'
    },
    {
        ...ScheduleMeta,
        day_of_week: 'SUN'
    },
];


export const translateDay = (dayOfWeek: string) => {
    switch (dayOfWeek) {
        case 'MON':
            return 'Segunda-Feira';
        case 'TUE':
            return 'Terça-Feira';
        case 'WED':
            return 'Quarta-Feira';
        case 'THU':
            return 'Quinta-Feira';
        case 'FRI':
            return 'Sexta-Feira';
        case 'SAT':
            return 'Sábado';
        case 'SUN':
            return 'Domingo';
        default:
            return '';
    }
}