import {ScheduleMetaModel} from "../models/Schedule";

export default function ScheduleMetaReducer(state: any, action: any){
    switch (action.type) {
        case "toggleEnabled":
            state.meta[action.index].enabled = !state.meta[action.index].enabled;
            return {...state}
        case "setFrom":
            state.meta[action.index].from = action.from;
            return {...state}
        case "setTo":
            state.meta[action.index].to = action.to;
            return {...state}
        case "setMaxDelay":
            state.meta[action.index].max_delay = action.max_delay;
            return {...state}
        default:
            return state;
    }
}